var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibitor-update-list-item"},[_c('div',{staticClass:"is-flex is-flex-direction-column is-flex-grow-1"},[_c('i18n',{staticClass:"exhibitor-update-list-item-title",attrs:{"path":"moderator.exhibitor_booth_update","tag":"div"}},[_c('span',{class:_vm.statusClass},[_vm._v(_vm._s(_vm.title))])]),_c('i18n',{staticClass:"exhibitor-update-list-item-time",attrs:{"path":"moderator.update_status_created_time","tag":"div"}},[_c('span',[_vm._v(_vm._s(_vm.createdAt))])]),(_vm.extraNote && (_vm.status === _vm.Constants.MODERATION_STATUS_REJECTED || _vm.status === _vm.Constants.MODERATION_STATUS_ACCEPTED))?_c('i18n',{staticClass:"exhibitor-update-list-item-note",attrs:{"tag":"div","path":"moderator.update_status_rejected_reason"}},[_c('span',[_vm._v(" "+_vm._s(_vm.extraNote)+" ")])]):_vm._e()],1),(_vm.buttonVisible)?_c('b-dropdown',{attrs:{"position":"is-bottom-left","trap-focus":"","can-close":"","close-on-click":""}},[_c('b-button',{staticClass:"pl-1 pr-1",attrs:{"slot":"trigger","type":"is-outlined"},slot:"trigger"},[_c('div',{staticClass:"exhibitor-update-list-item-button-icon"},[_c('dots-icon',{staticClass:"has-fill-primary"})],1)]),_c('b-dropdown-item',{attrs:{"has-link":true}},[_c('router-link',{attrs:{"to":{
            name: _vm.Constants.ROUTE_MODERATOR_EXHIBITOR_UPDATE,
            params: {
              exhibitorId: _vm.exhibitorId,
              exhibitorUpdateId: _vm.exhibitorUpdateId,
            },
          }}},[_vm._v(" "+_vm._s(_vm.$t("moderator.update_dropdown_show_and_edit"))+" ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }